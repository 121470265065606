import React from "react"

import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/BackToSchoolAuto"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { FormPKW } from "../components/FormPKW"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { InstallmentExample } from "../components/InstallmentExample"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"

import { NewFooter } from "../components/NewFooter"

import { ExampleWithLaptop } from "../components/InstallmentExample/Content/ExampleWithLaptop/ExampleWithLaptop"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { useHybridBanner } from "../hooks/useHybridBanner"

// Время для таймер
const countDownTime = 1800000

const formTitle = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const installmentTitle = <>Как выиграть автомобиль и&nbsp;другие призы?</>

const bannerSimpleTitle = `
Разыгрываем <br/> автомобиль
`

const bannerSimpleDescription = `
  • &nbsp; до 36 месяцев рассрочки
  <br />
  • &nbsp; до 5 000 баллов кэшбэка
  `

const calculatorText = (
  <>
    Стоимость покупки делится на равные ежемесячные платежи без&nbsp;процентов.
    <br />
    <br />А с акцией «Готовимся к школе с Халвой»! с 5 августа по 15 сентября (включительно) срок
    рассрочки будет до 36 месяцев на покупки у партнеров.
  </>
)

export default function BackToSchool({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  const banner = useHybridBanner(
    Banner as any,
    { title: bannerSimpleTitle, desc: bannerSimpleDescription },
    { orderNum: "1" },
    { orderNum: "1", isDynamicText: true },
    data.allDynamicBanner
  )

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank showHint={false} hasCTA countDownTime={countDownTime} />
      <div>{banner}</div>
      <CardWithMaxBenefits headTitle="Ваша выгода с Халвой" variant="btsAuto" orderNum="2" />
      <FormPKW
        formBtnText="Оформить карту"
        dataLayerName="shortPersonalForm"
        withTimer
        countDownTime={countDownTime}
        orderNum="3"
        title={formTitle}
        fioPlaceHolder="Фамилия Имя Отчество"
        variant="btsAuto"
        isGreenApprove
        progressBar
      />
      <HowInstallmentWorks headTitle={installmentTitle} variant="btsAuto" orderNum="4" />
      <NewCalculator
        variant="btsAuto"
        darkColor
        subtext={calculatorText}
        titleLocationBottom
        orderNum="5"
      />
      <InstallmentExample
        variantStyle="btsAuto"
        content={<ExampleWithLaptop />}
        orderNum="6"
        text="Ноутбук для учебы на отлично"
      />
      <MoreBenefitsRedisign
        variant="btsAuto"
        hasSubtitle={false}
        title="Еще больше призов"
        bottomBlock={false}
        orderNum="7"
      />
      <NewFooter ligal={ligal} orderNum="8" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/backtoschool-auto/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
